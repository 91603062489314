<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-dark">
      <div class="container-fluid">
        <!-- Logo and Home Link -->
        <RouterLink class="navbar-brand" to="/">
          <img
            src="../assets/White logo - no background.png"
            class="logo"
            alt="tegel winkel in Panjsher Afghanistan"
          />
        </RouterLink>

        <!-- Mobile Hamburger Menu -->
        <button
          class="navbar-toggler custom-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <!-- Navbar Links -->
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav ms-auto">
            <!-- Home -->
            <li class="nav-item">
              <RouterLink class="nav-link" to="/">Home</RouterLink>
            </li>

            <li class="nav-item">
              <RouterLink class="nav-link" to="/producten"
                >Assortiment</RouterLink
              >
            </li>

            <!-- Product Dropdown -->
            <!-- <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Producten
              </a>
              <ul
                class="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <li>
                  <RouterLink class="dropdown-item" to="/vloertegels"
                    >Vloertegels</RouterLink
                  >
                </li>
                <li>
                  <RouterLink class="dropdown-item" to="/wandtegels"
                    >Wandtegels</RouterLink
                  >
                </li>
                <li>
                  <RouterLink class="dropdown-item" to="/geveltegels"
                    >Geveltegels</RouterLink
                  >
                </li>
                <li>
                  <RouterLink class="dropdown-item" to="/terrastegels"
                    >Terrastegels</RouterLink
                  >
                </li>
              </ul>
            </li> -->

            <!-- Other Links -->
            <li class="nav-item">
              <RouterLink class="nav-link" to="/contact"
                >Klantenservice</RouterLink
              >
            </li>
            <li class="nav-item">
              <RouterLink class="nav-link" to="/locatie"
                >Transport & Kosten</RouterLink
              >
            </li>
            <li class="nav-item">
              <RouterLink class="nav-link" to="/veelgestelde-vragen"
                >Veelgestelde Vragen</RouterLink
              >
            </li>

            <!-- Cart Icon -->
            <li class="nav-item cart-icon">
              <RouterLink to="/cart" class="nav-link">
                <i class="fas fa-shopping-cart"></i>
                <span v-if="cartCount > 0" class="cart-count">{{
                  cartCount
                }}</span>
              </RouterLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { getCartCount } from "@/store/useCart";
import { onMounted, onUnmounted } from "vue";

const cartCount = computed(() => getCartCount()); // Reactive cart count

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});

function handleScroll() {
  const navbar = document.querySelector(".navbar");
  if (window.scrollY > 50) {
    navbar.classList.add("scrolled");
  } else {
    navbar.classList.remove("scrolled");
  }
}
</script>

<style lang="scss" scoped>
/* Base styles for the navbar */
.navbar {
  color: white;
  background: black; /* Initial background color */
  font-weight: bold;
  padding: 0.5rem 1rem;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000; /* Ensure the navbar stays on top */
  transition: background-color 0.3s ease-in-out; /* Smooth transition for background color */
}

/* Apply transparency effect when scrolled */
.navbar.scrolled {
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ); /* Slightly more transparent when scrolling */
}

/* Adjustments for the logo */
.navbar-brand {
  display: flex;
  align-items: center;
}

.logo {
  height: 2.5rem;
  width: auto;
  max-height: 100%;
}

/* Center and align navbar links */
.navbar-nav {
  margin-left: auto;
}

.nav-link {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  color: white;
}

/* Custom styles for the hamburger menu */
.custom-toggler {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.custom-toggler .navbar-toggler-icon {
  width: 1rem;
  height: 1rem;
}

/* Button styling for mobile navbar toggler */
.navbar-toggler {
  border: none;
}

/* Media queries for different devices */
@media (max-width: 1200px) {
  .logo {
    height: 2rem;
  }

  .nav-link {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .logo {
    height: 1.75rem;
  }

  .nav-link {
    font-size: 0.85rem;
    padding: 0.5rem 0.75rem;
  }

  .dropdown-menu {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 576px) {
  .logo {
    height: 1.5rem;
  }

  .nav-link {
    font-size: 0.8rem;
    padding: 0.5rem 0.5rem;
  }

  .navbar {
    padding: 0.25rem 0.5rem;
  }

  .dropdown-menu {
    font-size: 0.9rem;
  }
}

/* Cart icon styling */
.cart-icon {
  position: relative;
}

.cart-count {
  background-color: #ff0000;
  color: #fff;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 0.8rem;
  position: absolute;
  top: -8px;
  right: -10px;
}
</style>
