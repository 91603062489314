// src/store/useCart.js
import { ref } from "vue";

const cart = ref(JSON.parse(localStorage.getItem("cart")) || []);

export function addToCart(product, quantity) {
  const existingItem = cart.value.find(
    (item) => item.product.id === product.id
  );
  if (existingItem) {
    existingItem.quantity += quantity; // Increment quantity if product exists
  } else {
    cart.value.push({ product, quantity }); // Add new product
  }
  localStorage.setItem("cart", JSON.stringify(cart.value));
}

export function removeFromCart(index) {
  cart.value.splice(index, 1);
  localStorage.setItem("cart", JSON.stringify(cart.value));
}

export function clearCart() {
  cart.value = [];
  localStorage.removeItem("cart");
}

export function getCart() {
  return JSON.parse(localStorage.getItem("cart")) || [];
}

export function getCartCount() {
  return cart.value.length;
}
