<template>
  <div class="create-product-page">
    <h1>Create New Product</h1>

    <form @submit.prevent="submitTile">
      <div class="form-group">
        <label for="name">Name:</label>
        <input type="text" id="name" v-model="tile.name" />
      </div>

      <div class="form-group">
        <label for="description">Description:</label>
        <input type="text" id="description" v-model="tile.description" />
      </div>

      <div class="form-group">
        <label for="articleNumber">Article Number:</label>
        <input
          type="number"
          id="articleNumber"
          v-model.number="tile.articleNumber"
        />
      </div>

      <div class="form-group">
        <label for="price">Price (€):</label>
        <input
          type="number"
          step="0.01"
          id="price"
          v-model.number="tile.price"
        />
      </div>

      <div class="form-group">
        <label for="discount">Discount (%):</label>
        <input
          type="number"
          step="0.01"
          id="discount"
          v-model.number="tile.discount"
        />
      </div>

      <div class="form-group">
        <label for="color">Color:</label>
        <input type="text" id="color" v-model="tile.color" />
      </div>

      <div class="form-group">
        <label for="brand">Brand:</label>
        <input type="text" id="brand" v-model="tile.brand" />
      </div>

      <div class="form-group">
        <label for="material">Material:</label>
        <input type="text" id="material" v-model="tile.material" />
      </div>

      <div class="form-group">
        <label for="executionType">Execution Type:</label>
        <input type="text" id="executionType" v-model="tile.executionType" />
      </div>

      <div class="form-group">
        <label for="applicationType">Application Type:</label>
        <input
          type="text"
          id="applicationType"
          v-model="tile.applicationType"
        />
      </div>

      <div class="form-group">
        <label for="length">Length (cm):</label>
        <input
          type="number"
          step="0.01"
          id="length"
          v-model.number="tile.length"
        />
      </div>

      <div class="form-group">
        <label for="width">Width (cm):</label>
        <input
          type="number"
          step="0.01"
          id="width"
          v-model.number="tile.width"
        />
      </div>

      <div class="form-group">
        <label for="thickness">Thickness (cm):</label>
        <input
          type="number"
          step="0.01"
          id="thickness"
          v-model.number="tile.thickness"
        />
      </div>

      <div class="form-group">
        <label for="weightPerTile">Weight per Tile (kg):</label>
        <input
          type="number"
          step="0.01"
          id="weightPerTile"
          v-model.number="tile.weightPerTile"
        />
      </div>

      <div class="form-group">
        <label for="m2PerPack">M² per Pack:</label>
        <input
          type="number"
          step="0.01"
          id="m2PerPack"
          v-model.number="tile.m2PerPack"
        />
      </div>

      <div class="form-group">
        <label for="tilesPerPack">Tiles per Pack:</label>
        <input
          type="number"
          step="0.01"
          id="tilesPerPack"
          v-model.number="tile.tilesPerPack"
        />
      </div>

      <div class="form-group">
        <label for="edgeType">Edge Type:</label>
        <input type="text" id="edgeType" v-model="tile.edgeType" />
      </div>

      <div class="form-group">
        <label for="eanCode">EAN Code:</label>
        <input type="text" id="eanCode" v-model="tile.eanCode" />
      </div>

      <div class="form-group">
        <label for="layInstructions">Lay Instructions:</label>
        <textarea
          id="layInstructions"
          v-model="tile.layInstructions"
        ></textarea>
      </div>

      <div class="form-group">
        <label for="feature">Feature:</label>
        <textarea id="feature" v-model="tile.feature"></textarea>
      </div>

      <div class="form-group">
        <label for="maintenanceInstructions">Maintenance Instructions:</label>
        <textarea
          id="maintenanceInstructions"
          v-model="tile.maintenanceInstructions"
        ></textarea>
      </div>

      <div class="form-group">
        <label for="images"
          >Upload Images (10MB max per foto, 50MB per product):
        </label>
        <input
          type="file"
          id="images"
          multiple
          @change="handleFileUpload"
          required
        />
      </div>

      <button type="submit" class="btn btn-primary">Create Product</button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { ref } from "vue";
const apiUrl = process.env.VUE_APP_API_URL;

export default {
  name: "CreateProductPage",
  setup() {
    const tile = ref({
      name: "",
      description: "",
      articleNumber: null,
      price: null,
      discount: null,
      color: "",
      brand: "",
      material: "",
      executionType: "",
      applicationType: "",
      length: null,
      width: null,
      thickness: null,
      weightPerTile: null,
      m2PerPack: null,
      tilesPerPack: null,
      edgeType: "",
      eanCode: "",
      layInstructions: "",
      feature: "",
      maintenanceInstructions: "",
    });

    const images = ref([]);

    const handleFileUpload = (event) => {
      const maxFileSize = 10 * 1024 * 1024; // 10 MB

      for (let file of event.target.files) {
        console.log(event.target.files);
        console.log(file);
        if (file.size > maxFileSize) {
          alert(`File ${file.name} exceeds the maximum size of 10MB.`);
          return;
        }
      }

      images.value = Array.from(event.target.files);
    };

    const submitTile = async () => {
      try {
        const formData = new FormData();
        formData.append(
          "tile",
          new Blob([JSON.stringify(tile.value)], { type: "application/json" })
        );
        images.value.forEach((image) => {
          formData.append("images", image);
        });

        const response = await axios.post(`${apiUrl}/api/tiles`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        console.log("Product created:", response);
        alert("Product created successfully!");
      } catch (error) {
        console.error("Error creating product:", error);
        alert("Failed to create product.");
      }
    };

    return {
      tile,
      images,
      handleFileUpload,
      submitTile,
    };
  },
};
</script>

<style scoped>
.create-product-page {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input,
textarea,
select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  padding: 10px 20px;
  background-color: #42b983;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #369f73;
}
</style>
