<template>
  <div class="templatee">
    <div class="not-found card">
      <h1>404 Not Found</h1>
      <p>Deze pagina bestaat niet, ga terug naar:</p>
      <!-- Go Back to Homepage Button -->
      <router-link to="/" class="back-home-btn">
        <i class="fas fa-home"></i> Home
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>
.templatee {
  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */
  position: relative; /* Make sure it positions elements correctly */
  /* margin: 50px; */
  height: 70vh;
}

.not-found {
  text-align: center;
  padding: 50px;
}

.not-found h1 {
  font-size: 4rem;
  color: #ff6b6b;
}

.not-found p {
  font-size: 1.5rem;
  color: #333;
}

.card {
  /* background-color: #f8f9fa9e; */
  width: 50%;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #ffffffc2;
  border: none;
}

.back-home-btn {
  background-color: #555;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  text-decoration: none;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  border: 1px solid white;
  max-width: 200px;
  margin: 20px auto;
  box-sizing: border-box;
}

.back-home-btn:hover {
  background-color: black;
}

.back-home-btn i {
  font-size: 1.25rem;
}

/* Responsive styling for smaller screens */
@media (max-width: 600px) {
  .card {
    width: 90%;
  }
}
</style>
