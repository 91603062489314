<template>
  <div class="contact-page">
    <!-- Hero Section -->
    <section class="hero-section">
      <div class="hero-container">
        <h1>Neem Contact Op</h1>
        <p>
          We horen graag van u! Vul het onderstaande formulier in of gebruik de
          contactgegevens om ons te bereiken.
        </p>
      </div>
    </section>

    <!-- Contact Information Section -->
    <section class="contact-info-section">
      <div class="container">
        <div class="contact-info-grid">
          <div class="contact-info-item">
            <i class="fas fa-map-marker-alt"></i>
            <h3>Ons Adres</h3>
            <p>Steenweg op Mol 187, 2360 Oud-Turnhout, België</p>
          </div>
          <div class="contact-info-item">
            <i class="fas fa-phone"></i>
            <h3>Telefoon</h3>
            <p>+31 644342494</p>
          </div>
          <div class="contact-info-item">
            <i class="fas fa-envelope"></i>
            <h3>Email</h3>
            <p>info@panjsher.be</p>
          </div>
          <div class="contact-info-item">
            <i class="fas fa-clock"></i>
            <h3>Openingstijden</h3>
            <p>
              Maandag - Vrijdag: 09:00 - 17:00<br />Zaterdag: 10:00 - 15:00<br />Zondag:
              Gesloten
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- Contact Form Section -->
    <section class="contact-form-section">
      <div class="container contact-info-item">
        <h2>Stuur ons een Bericht</h2>
        <form @submit.prevent="handleSubmit">
          <div class="form-group">
            <label for="name">Naam</label>
            <input type="text" id="name" v-model="form.name" required />
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <input type="email" id="email" v-model="form.email" required />
          </div>
          <div class="form-group">
            <label for="message">Bericht</label>
            <textarea
              id="message"
              v-model="form.message"
              rows="5"
              required
            ></textarea>
          </div>
          <button type="submit" class="btn-submit">Verzend</button>
        </form>
      </div>
    </section>
  </div>

  <!-- Loading Popup -->
  <div v-if="loading" class="loading-popup">
    <div class="loading-content">
      <p>Verzenden... Even geduld aub.</p>
      <div class="loader"></div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";

const router = useRouter();

const form = ref({
  name: "",
  email: "",
  message: "",
});

const submitted = ref(false);
const loading = ref(false); // Loading state

const handleSubmit = () => {
  submitted.value = true;

  // Check if all fields are filled
  if (!form.value.name || !form.value.email || !form.value.message) {
    alert("Vul alle verplichte velden in.");
    return;
  }

  const requestData = {
    name: form.value.name,
    email: form.value.email,
    message: form.value.message,
  };

  // Set loading state to true before sending the request
  loading.value = true;

  const apiUrl = process.env.VUE_APP_API_URL;

  // Send the form data to the backend using Axios
  axios
    .post(apiUrl + "/api/send-email", requestData)
    .then((response) => {
      console.log("Email sent:", response.data);

      form.value.name = "";
      form.value.email = "";
      form.value.message = "";

      // Scroll naar de bovenkant van de pagina
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      // Verwijs naar de bevestigingspagina na een succesvolle aanvraag
      router.push({ path: "/contact-succes" });
    })
    .catch((error) => {
      console.error("Error sending email:", error);
      alert("Er is iets misgegaan bij het verzenden van uw bericht.");
    })
    .finally(() => {
      // Set loading state to false after the request completes
      loading.value = false;
    });
};
</script>

<style scoped>
/* Existing styles as per your original ContactPage.vue */
.contact-page {
  font-family: Arial, sans-serif;
  color: #333;
  line-height: 1.6;
}

/* Hero Section */
.hero-section {
  background: url("../assets/tile_shop_panjsheer.jpg") no-repeat center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  padding: 100px 20px;
}

.hero-section .hero-container {
  max-width: 700px;
  padding: 20px;
  background: rgba(0, 0, 0, 0.616);
  border-radius: 10px;
}

.hero-section h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.hero-section p {
  font-size: 1.5rem;
}

/* Contact Information Section */
.contact-info-section {
  padding: 50px 20px;
  /* background-color: #f8f8f8; */
}

.contact-info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.contact-info-item {
  text-align: center;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.1);
}

.contact-info-item i {
  font-size: 2rem;
  color: #42b983;
  margin-bottom: 10px;
}

.contact-info-item h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.contact-info-item p {
  font-size: 1rem;
  color: #555;
}

/* Contact Form Section */
.contact-form-section {
  padding-bottom: 50px;
  /* background-color: #ffffff; */
}

.contact-form-section h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #2c3e50;
}

form {
  max-width: 600px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.btn-submit {
  background-color: #42b983;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease;
}

.btn-submit:hover {
  background-color: #369f73;
}

/* Loading Popup Styles */
.loading-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.loading-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #42b983;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
