<template>
  <div v-if="visible" class="snackbar" :class="{ show: visible }">
    {{ message }}
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
    duration: {
      type: Number,
      default: 3000, // Default duration in milliseconds
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  mounted() {
    this.visible = true;
    setTimeout(() => {
      this.visible = false;
      this.$emit("dismiss"); // Emit an event when the snackbar disappears
    }, this.duration);
  },
};
</script>

<style scoped>
.snackbar {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #09d65b;
  color: #fff;
  padding: 16px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 0.3s ease-in-out, bottom 0.3s ease-in-out;
  z-index: 1000;
}
.snackbar.show {
  opacity: 1;
  bottom: 30px;
}
</style>
